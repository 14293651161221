import { ArrowRightIcon } from "@heroicons/react/solid";
import React from "react";

export default function Navbar() {
  return (
    <header className="bg-gray-800 md:sticky top-0 z-10">
      <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
        <div className="title-font font-medium text-white mb-4 md:mb-0">
          <button href="#" className="md:ml-3 text-xl">
            Juan Lopez
          </button>
        </div>
        <div className="hidden md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-700 md:flex justify-center">
          <a href="#skills" className="mr-5 block hover:text-white">
            My Skills
          </a> <br />
          <a href="#projects" className="mr-5  hover:text-white">
            My Projects
          </a>
        </div>

        <a
          href="https://linkedin.com/in/juanlopez14" target="_blank" rel="noreferrer"
          className="inline-flex items-center space-right2 bg-gray-800 border-0 py-1 px-3 focus:outline-none hover:bg-gray-700
          rounded text-base md:mt-0"> Reach Out &nbsp;

        <svg fill="white" className="space-x-11" xmlns="http://www.w3.org/2000/svg" height="24" width="20" viewBox="0 0 448 512">
          <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/>
        </svg>

          <ArrowRightIcon className="w-4 h-4 ml-1" />
        </a>
      </div>
    </header>
  );
}