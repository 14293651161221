export const projects = [
  {
    title: "Uber Clone",
    subtitle: "React and Vercel",
    description: "Uber clone created with ReactJS,  Google Maps API, and Clerk Authentication. Users can search for rides. ",
    image: "./uber-clone.png",
    link: "https://uber-app-dusky.vercel.app/",
  },
  {
    title: "E-commerce Shop",
    subtitle: "Payload",
    description:
      "E-commerce Shop is a fully-functional e-commerce platform for seamless shopping experiences!",
    image: "./ecomm.jpg",
    link: "https://ecom-5983e60.payloadcms.app/",
  },
  {
    title: "Social Media App",
    subtitle: "React and Vercel",
    description:
      "Social Hub for Authentic Connections! Post your moments, share captivating images, and dive into meaningful conversations with our vibrant community",
    image: "./social_app.jpg",
    link: "https://connectgram.netlify.app",
  },
  {
    title: "Music Player",
    subtitle: "React and Shazaam API",
    description:
      "Music player in React and using trial music Shazaam API.",
    image: "./music-player.jpg",
    link: "https://liric.netlify.app",
  },
  {
    title: "Movie App",
    subtitle: "React and Movie API",
    description:
      "A movie app built in React and using Movie API.",
    image: "./movies.jpg",
    link: "https://mov123.netlify.app",
  },
  {
    title: "Travel App",
    subtitle: "React and Tailwind",
    description:
      "A travel/camping site built in React and Tailwind.",
    image: "./travel.jpg",
    link: "https://campi.netlify.app/",
  },
];


export const skills = [
  "HTML5",
  "CSS3",
  "JavaScript",
  "React.js",
  "Next.js",
  "Tailwind.css",
  "Node.js",
  "GraphQL",
  "Netlify",
  "AppWrite",
  "APIs",
  "Material UI"
];
