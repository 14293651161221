import React from "react";


export default function Footer() {
  return (
    <section id="copyright" className="full-width bg-gray-800">
      <footer className="container px-5 py-10 mx-auto text-center bg-gray-800">

        <div className="flex flex-wrap justify-center text-white">
          © {new Date().getFullYear()} All Rights Reserved.
        </div>

      </footer>
    </section>
  );
}

